
/*
 * @Author: Haoxiwei 1078734933@qq.com
 * @Date: 2023-04-24 11:22:25
 * @LastEditors: Haoxiwei 1078734933@qq.com
 * @LastEditTime: 2023-07-20 09:47:21
 * @FilePath: /WeMeet_admin_project/wemeet-admin/src/api/roleManagement.js
 * @Description: 角色权限接口
 */
import request from '@/utils/request'

/**
 * @description: 查询iam角色列表
 * @param {*} params
 * @return {*}
 */
export function getList(params) {
  return request({
    url: '/iam/role/query/tenant/roleList',
    method: 'get',
    params,
  })
}

/**
 * @description: 删除角色
 * @param {*} roleId
 * @return {*}
 */
export function doDelete(roleId) {
  return request({
    url: `/iam/role/delete/${roleId}`,
    method: 'delete',
  })
}

/**
 * @description: 角色详情
 * @param {*} roleId
 * @return {*}
 */
export function getRoleDetail(roleId) {
  return request({
    url: `/iam/role/detail/${roleId}`,
    method: 'get',
  })
}

/**
 * @description: 添加角色
 * @return {*}
 */
export function addRole(data) {
  return request({
    url: `/iam/role/add`,
    method: 'post',
    data,
  })
}

/**
 * @description: 修改角色
 * @return {*}
 */
export function updateRole(data) {
  return request({
    url: `/iam/role/update`,
    method: 'put',
    data,
  })
}



/**
 * @description: 获取下拉列表角色
 * @return {*}
 */
 export function getSelectListRole() {
  return request({
    url: `/iam/role/listOption`,
    method: 'get',
  })
}
