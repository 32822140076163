<template>
  <div class="role-management-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="12">
        <el-button :icon="Plus" type="primary" @click="handleEdit($event)">
          添加
        </el-button>
        <!-- <el-button :icon="Delete" type="danger" @click="handleDelete($event)">
          批量删除
        </el-button> -->
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="12">
        <el-form :inline="true" :model="queryForm" @submit.prevent>
          <el-form-item>
            <el-input v-model.trim="queryForm.roleNameLike" clearable placeholder="请输入角色" />
          </el-form-item>
          <el-form-item>
            <el-button :icon="Search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table v-loading="listLoading" border :data="list">

      <el-table-column align="center" label="角色名称" prop="roleName" show-overflow-tooltip />
      <el-table-column align="center" label="角色码" prop="roleKey" show-overflow-tooltip />
      <el-table-column align="center" label="操作" show-overflow-tooltip width="195">
        <template #default="{ row }">
          <el-button @click="handleEdit(row)" type="text">编辑</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
      <template #empty>
        <el-image class="vab-data-empty" :src="require('@/assets/empty_images/data_empty.png')" />
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination background :current-page="queryForm.pageNo" :layout="layout" :page-size="queryForm.pageSize" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    <edit ref="editRef" @fetch-data="fetchData" />
  </div>
</template>

<script>
import {
  defineAsyncComponent,
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  computed,
} from 'vue'
import { doDelete, getList, getRoleDetail } from '@/api/roleManagement'
import { Delete, Plus, Search } from '@element-plus/icons'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'RoleManagement',
  components: {
    Edit: defineAsyncComponent(() => import('./components/RoleManagementEdit')),
  },
  setup() {
    const { proxy } = getCurrentInstance()

    const store = useStore()
    const state = reactive({
      editRef: null,
      list: [],
      listLoading: true,
      layout: 'prev, pager, next',
      total: 0,
      selectRows: '',
      queryForm: {
        page: 1,
        pageSize: 10,
        role: '',
      },
    })

    const setSelectRows = (val) => {
      state.selectRows = val
    }

    const handleEdit = async (row) => {
      if (row.id) {
       const { data } = await  getRoleDetail(row.id)
        state['editRef'].showEdit(data)
      } else {
        state['editRef'].showEdit()
      }
    }
    const handleDelete = (row) => {
      if (row.id) {
        proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await doDelete(row.id)
          proxy.$baseMessage(msg, 'success', 'vab-hey-message-success')
          await fetchData()
        })
      }
    }
    const handleSizeChange = (val) => {
      state.queryForm.pageSize = val
      fetchData()
    }
    const handleCurrentChange = (val) => {
      state.queryForm.page = val
      fetchData()
    }
    const queryData = () => {
      state.queryForm.page = 1
      fetchData()
    }
    const fetchData = async () => {
      state.listLoading = true
      let userInfo = store.getters['user/user']
      state.queryForm.tid = userInfo.tid
      const { data, total } = await getList(state.queryForm)
      state.list = data.list
      state.total = data.total
      state.listLoading = false
    }
    onMounted(() => {
      fetchData()
    })

    return {
      ...toRefs(state),
      setSelectRows,
      handleEdit,
      handleDelete,
      handleSizeChange,
      handleCurrentChange,
      queryData,
      fetchData,
      Delete,
      Plus,
      Search,
    }
  },
})
</script>
